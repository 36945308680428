<template>
    <router-link v-bind="routeProps" :data-test-id="$testId('button.policy')" target="_blank">
        <slot>{{ $t('_common:terms.travelExperiencesPolicy') }}</slot>
    </router-link>
</template>

<script>
    import get from 'lodash/get';
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        name: 'TravelExperiencePolicyLink',
        props: {
            country: {
                type: String,
                default: ''
            },
            language: {
                type: String,
                default: 'en'
            },
            isPackageOffer: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            routeProps( ) {
                if ( this.country !== 'US' ) {
                    let link;
                    const urlLinkKey = 'travelExperiencePolicy';

                    if ( tenantUrlsConstants.COUNTRIES.hasOwnProperty( this.country ) ) {
                        link = get( tenantUrlsConstants.COUNTRIES, [ this.country, urlLinkKey, this.language ] );
                        if ( !link ) {
                            link = get( tenantUrlsConstants.COUNTRIES, [ this.country, urlLinkKey, tenantUrlsConstants.COUNTRIES[this.country].defaultLanguage ] );
                        }
                    }
                    if ( !link ) {
                        return { to: { name: this.isPackageOffer ? 'policyPackageOffer' : 'policy' } };
                    }
                    return {
                        href: link,
                        to: '/'
                    };
                } else {
                    return { to: { name: this.isPackageOffer ? 'policyPackageOffer' : 'policy' } };
                }
            }
        }
    };
</script>